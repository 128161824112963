<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>

        <div class="page-title-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li
                v-for="(item, index) in items"
                :key="index"
                class="breadcrumb-item"
                :class="{ active: item.active }"
              >
                <template v-if="item.active">
                  {{ item.text }}
                </template>
                <template v-else>
                  <router-link :to="item.href">{{ item.text }}</router-link>
                </template>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
