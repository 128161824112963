<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Simulasi Config",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Edit Simulasi Config",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Simulasi Config",
          href: "/master/simulasi-config",
        },
        {
          text: "Edit Simulasi Config",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      id: this.$route.params.id,
      url_backend_file: process.env.VUE_APP_BACKEND_URL,

      option_jumlah_petunjuk: ["Single", "Multiple"],
      option_jumlah_soal: ["Single", "Multiple"],
      option_tipe_jawaban: ["Rich Text", "PPT (Only Office)", "Rich Text - Interaction", "PPT (Only Office) - Interaction", "Rich Text - Discussion"],
      option_jumlah_jawaban: ["Single", "1 Soal 1 Jawaban"],
      option_tipe_penjadwalan: ["1 Simulasi 1 Batch", "1 Simulasi per orang beda batch"],
      option_tipe_code_analytic: ["jawaban", "upload file"],
      // variable Page
      form_data: {
        nama_simulasi: null,
        kode_simulasi: null,
        durasi: null,
        jumlah_petunjuk: null,
        jumlah_soal: null,
        tipe_jawaban: null,
        jumlah_jawaban: null,
        icon: "",
        deskripsi: "",
        status: null,
        acakan_simulasi: "",
        tipe_penjadwalan: null,
        opsi_tipe_kode_analitic: null,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-config/" + self.id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function (response) {
          Swal.close();
          var response_data = response.data.data;
          self.form_data.deskripsi = response_data.deskripsi;
          self.form_data.nama_simulasi = response_data.nama;
          self.form_data.durasi = response_data.durasi;
          self.form_data.kode_simulasi = response_data.sub_nama;
          self.form_data.jumlah_jawaban = response_data.jumlah_jawaban;
          self.form_data.jumlah_petunjuk = response_data.jumlah_petunjuk;
          self.form_data.jumlah_soal = response_data.jumlah_soal;
          self.form_data.tipe_jawaban = response_data.tipe_jawaban;
          self.form_data.icon = response_data.icon;
          self.form_data.tipe_penjadwalan = response_data.tipe_penjadwalan;
          self.form_data.opsi_tipe_kode_analitic = response_data.tipe_code_analytics;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    // Method dari masing masing fungsi di dalam file ini
    updateData() {
      let self = this;
      if (containsDisallowedCharacters(self.form_data.deskripsi)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return; // Exit the function if validation fails
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_update_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-config/" + self.id,
        data: this.form_data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_update_data)
        .then(function () {

          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi config segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-simulasi-config" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    async uploadFile(event) {
      var file = event.target.files[0];
      let self = this;

      if (file) {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        if (file.size < 2242880) {
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", file);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            var path_file = response.data.data.path_file;

            self.form_data.icon = path_file;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "File berhasil ditambahkan",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          });
        } else {
          event.target.value = null;
          Swal.fire({
            icon: "error",
            title: "oops",
            text: "Maksimal File 2 MB",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
            }
          });
        }
      }
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form
            class="p-2"
            @submit.prevent="updateData"
          >
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String"
                >
                  {{ axiosCatchError }}
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-else
                >
                  <div
                    v-for="(errorArray, idx) in axiosCatchError"
                    :key="idx"
                  >
                    <div
                      v-for="(allErrors, idx) in errorArray"
                      :key="idx"
                    >
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Nama Simulasi"
                    label-for="formrow-nama-role-input"
                  >
                    <b-form-input
                      id="formrow-nama-role-input"
                      placeholder="Masukkan Nama Simulasi..."
                      type="text"
                      v-model="form_data.nama_simulasi"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Kode Simulasi"
                    label-for="formrow-nama-role-input"
                  >
                    <b-form-input
                      id="formrow-nama-role-input"
                      placeholder="Masukkan Kode Simulasi..."
                      type="text"
                      v-model="form_data.kode_simulasi"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <label>Jumlah Petunjuk</label>
                  <multiselect
                    v-model="form_data.jumlah_petunjuk"
                    :options="option_jumlah_petunjuk"
                    placeholder="Pilih Jumlah Petunjuk"
                  ></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Jumlah Soal</label>
                  <multiselect
                    v-model="form_data.jumlah_soal"
                    :options="option_jumlah_soal"
                    placeholder="Pilih Jumlah Soal"
                  ></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Tipe Jawaban</label>
                  <multiselect
                    v-model="form_data.tipe_jawaban"
                    :options="option_tipe_jawaban"
                    placeholder="Pilih Tipe Jawaban"
                  ></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Jumlah Jawaban</label>
                  <multiselect
                    v-model="form_data.jumlah_jawaban"
                    :options="option_jumlah_jawaban"
                    placeholder="Pilih Jumlah Jawaban"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-3">
                  <label>Tipe Penjadwalan</label>
                  <multiselect
                    v-model="form_data.tipe_penjadwalan"
                    :options="option_tipe_penjadwalan"
                    placeholder="Pilih Jumlah Petunjuk"
                  ></multiselect>
                </div>
                <!-- 
                  <div class="col-md-3" v-if="form_data.tipe_penjadwalan == '1 Simulasi 1 Batch'">
                    <b-form-group class="mb-3" label="Durasi" label-for="formrow-nama-role-input">
                      <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Durasi..." type="number" v-model="form_data.durasi"></b-form-input>
                    </b-form-group>
                  </div> 
                -->
                <div class="col-md-3">
                  <b-form-group
                    class="mb-3"
                    label="Durasi"
                    label-for="formrow-nama-role-input"
                  >
                    <b-form-input
                      id="formrow-nama-role-input"
                      placeholder="Masukkan Durasi..."
                      type="number"
                      v-model="form_data.durasi"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label
                      for="formFile"
                      class="form-label"
                    >Icon</label>
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      ref="inputFile"
                      v-on:change="uploadFile"
                    />
                    <a
                      v-if="form_data?.icon"
                      :href="url_backend_file + form_data?.icon"
                      target="_blank"
                      class="btn btn-primary btn-sm mt-2"
                    ><i class="fa fa-eye me-2"></i>Lihat File Sekarang</a>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label
                      for="formFile"
                      class="form-label"
                    >Deskripsi</label>
                    <textarea
                      v-model="form_data.deskripsi"
                      class="form-control"
                      name="textarea"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label>Opsi Tipe Kode Analytic</label>
                    <multiselect
                      v-model="form_data.opsi_tipe_kode_analitic"
                      :options="option_tipe_code_analytic"
                      placeholder="Pilih Tipe Kode Analytic"
                      required
                    ></multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link
                      :to="{ name: 'all-simulasi-config' }"
                      class="btn btn-warning"
                    ><i class="fa fa-arrow-left"></i> Kembali</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button
                      type="reset"
                      variant="danger"
                    ><i class="fa fa-redo-alt"></i> Reset</b-button>
                    &nbsp;
                    <b-button
                      type="submit"
                      variant="primary"
                    ><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
